module AnagService {
    let app = angular.module("app");

    app.factory("AnagService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            
            getUserById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/other-user-info/:userId', {
                userId: "@userId",
            }, {
                query: {
                     method: 'GET',
                     isArray: false 
                }
            }),

            updateUserOptions: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-userOptions', {                
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }),

            getApplicationsEnabled: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-applications-enabled/:userId', {
                userId: "@userId",
            }, {
                query: {
                     method: 'GET',
                     isArray: false 
                }
            })


        };
        return serv;
    }]);
}